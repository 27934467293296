.circle {
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  box-shadow: inset 0 0 0 1px #00000030;
  flex-shrink: 0;
}

.circle:hover {
  cursor: pointer;
}
