.retentionChart {
  width: 100%;
  font-size: smaller;
  padding-bottom: 0.5rem;
  white-space: nowrap;
}

.retentionChart th {
  text-align: center;
}

.retentionChart td, .retentionChart th {
  width: 7%;
  padding: 0.1rem 0.7rem;
}

.retentionChart td.startDate, .retentionChart td.initialCount {
  text-align: right;
}

.retentionChart td.rate {
  border-radius: 3px;
  text-align: center;
}
