.wrapper :global(.Polaris-Box > .Polaris-VerticalStack > img) {
  max-width: 12rem;
  margin: 2rem 0;
}

.workflowCards :global(.Polaris-HorizontalGrid .Polaris-LegacyCard + .Polaris-LegacyCard) {
  margin-top: 0;
}

.workflowCards :global(.Polaris-HorizontalGrid .Polaris-LegacyCard) {
  height: 100%;
  position: relative;
  margin-bottom: 65px;
}

.workflowCards :global(.Polaris-HorizontalGrid .Polaris-LegacyCard > .Polaris-LegacyCard__Footer) {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.workflowCards :global(.Polaris-HorizontalGrid .Polaris-LegacyCard > .Polaris-LegacyCard__Footer *) {
  width: 100%;
}
