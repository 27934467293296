.wrapper :global(.Polaris-CalloutCard__Image) {
  width: 10rem;
}

.wrapper :global(.Polaris-CalloutCard__Buttons) {
  display: none;
}

.legacyWrapper :global(.Polaris-CalloutCard__Image) {
  width: 13rem;
}
