.paginationWrapper :global(.Polaris-ButtonGroup__Item) {
  z-index: 1;
}

.calendarWrapper :global(.fc-popover-body) {
  max-height: 400px;
  overflow-y: scroll;
}

.calendarWrapper :global(.fc-daygrid-event) {
  cursor: pointer;
  padding: 0px 4px;
  white-space: normal;
}

.spinnerWrapper svg{
  display: block;
}
