.widget {
  display: flex;
}

.widget > * {
  min-width: 2.375rem;
}

.widget > *:not(:first-child) {
  margin-left: 1.0rem;
}
