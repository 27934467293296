.wrap :global(.Polaris-Label) {
  margin-bottom: 0.25rem;
}

.fieldsWrap {
  display: flex;
  column-gap: 2rem;
  row-gap: 0.625rem;
  align-items: center;
  flex-wrap: wrap;
}

.dateWrap {
  display: flex;
  column-gap: 0.625rem;
  align-items: center;
  flex-grow: 1;
}

.dateWrap > :nth-child(1) {
  flex-grow: 1;
}

.dateWrap > :nth-child(2) {
  min-width: 70px;
}

.dateWrap > :nth-child(3) {
  min-width: 100px;
}

.hourWrap {
  display: flex;
  column-gap: 0.625rem;
  align-items: center;
  flex-grow: 1;
}

.hourWrap > :nth-child(1), .hourWrap > :nth-child(3) {
  min-width: 70px;
  flex-grow: 1;
}

