
.tableWrap th:nth-child(1), .tableWrap td:nth-child(3), .tableWrap td:nth-child(4), .tableWrap td:nth-child(5) {
  width: 1%;
  white-space: nowrap;
}

.tableWrap td:nth-child(2) {
  white-space: break-spaces;
}

@media (max-width: 40em) {
  .tableWrap th:nth-child(1), .tableWrap td:nth-child(3) {
    display: none;
  }
}

.tableWrap tbody tr:last-child :global(.Polaris-DataTable__Cell) {
  border-bottom: none;
}
