.header {
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  border-bottom: 0.0625rem solid var(--p-border-subdued);
}

.entries {
  position: relative;
  padding-top: 1.875rem;
}

.entries:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  left: 2.15625rem;
  width: 0.1875rem;
  background: var(--p-border-subdued,#dfe3e8);
}

.entry:last-child:after {
  content: "";
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 2.15625rem;
  margin-top: 0.6rem;
  width: 0.1875rem;
  z-index: 1.25;
  background: var(--p-background,#f4f6f8);
}

.entry {
  margin-bottom: 1.25rem;
  margin-top: 0.75rem;
  position: relative;
}

.entryBasic {
  display: flex;
  align-items: center;
}

.entryBullet {
  z-index: 2;
  flex-shrink: 0;
  width: 1.1875rem;
  height: 1.1875rem;
  margin-right: 1.0rem;
  margin-left: 1.65625rem;
  border: 3px solid var(--p-background,#dfe3e8);
  border-radius: 100%;
  background-color: var(--p-icon-subdued,#c4cdd5);
}

.entryMessage {
  flex: 1 1 auto;
  padding-right: 1rem;
  word-break: break-word;
}

.entryMessageWithDetails {
  cursor: pointer;
}

.entryMessage :global(.Polaris-Icon) {
  display: inline-block;
  vertical-align: middle;
}

.entryTime {
  align-self: flex-start;
  margin-right: 1.25rem;
  white-space: nowrap;
  color: var(--p-text-subdued,#637381);
}

.entryDetails {
  margin: 0 0.9375rem 0 4.375rem;
}

.headerLine {
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

.dateLine {
  margin: 0.9375rem 0 1.25rem 3.84375rem;
  color: var(--p-text-subdued,#637381);
}

.diffLine {
  display: flex;
  align-items: center;
  margin: 0.0625rem 0 0.6375rem 0;
}

.diffLineContent {
  flex: 1 1 0;
  white-space: pre-line;
  font-size: 0.75rem;
}

.diffLineArrow {
  margin: 0 0.9375rem;
}

.diffLineArrow > span {
  width: 0.9375rem;
  height: 0.9375rem;
}
